var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.center)?_c('gmap-map',{staticStyle:{"width":"100vw","height":"100vh"},attrs:{"zoom":6,"center":_vm.center,"options":{
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUI: false,
    }}},_vm._l((_vm.branchList),function(branch,index){return _c('gmap-marker',{key:index,attrs:{"position":{
        lat: parseFloat(branch.latitude),
        lng: parseFloat(branch.longitude),
        zoom: 6,
      },"icon":_vm.marker},on:{"click":function($event){_vm.center = {
          lat: parseFloat(branch.latitude),
          lng: parseFloat(branch.longitude),
          zoom: 6,
        }}}})}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }