<template>
  <div>
    <gmap-map
      v-if="center"
      :zoom="6"
      :center="center"
      :options="{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: false,
      }"
      style="width: 100vw; height: 100vh"
    >
      <gmap-marker
        v-for="(branch, index) in branchList"
        :key="index"
        :position="{
          lat: parseFloat(branch.latitude),
          lng: parseFloat(branch.longitude),
          zoom: 6,
        }"
        :icon="marker"
        @click="
          center = {
            lat: parseFloat(branch.latitude),
            lng: parseFloat(branch.longitude),
            zoom: 6,
          }
        "
      >
      </gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
import CommonService from '@/api/service/CommonService'
import { Geolocation } from '@capacitor/geolocation'
export default {
  data() {
    return {
      marker: {
        url: require('@/assets/images/marker.png'),
      },
      center: null,
      branchList: [],
    }
  },
  mounted() {
    this.getCurrentLoaction()
  },
  created() {
    this.getBranch()
  },
  methods: {
    async getCurrentLoaction() {
      this.isBusy = true
      try {
        const coordinates = await Geolocation.getCurrentPosition()
        this.isBusy = false
        if (coordinates) {
          const lat = coordinates.coords.latitude
          const lang = coordinates.coords.longitude
          this.branchList.push({ latitude: lat, longitude: lang })
          console.log(this.branchList)
        }
      } catch (err) {
        this.isBusy = false
        this.showAlert(err.message)
      }
    },
    async getBranch() {
      try {
        const res = await CommonService.getBranch()
        if (res.status == 200) {
          this.branchList.push(...res.data.data)
          if (this.branchList.length > 0) {
            this.center = {
              lat: parseFloat(this.branchList[0].latitude),
              lng: parseFloat(this.branchList[0].longitude),
              zoom: 6,
            }
          }
        }
      } catch (err) {
        this.showAlert(err)
      }
    },
  },
}
</script>

<style></style>
